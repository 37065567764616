import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { i18n } from '../../translate/i18n'
import ButtonWithSpinner from '../ButtonWithSpinner'
import toastError from '../../errors/toastError'
import axios from 'axios'
import api from '../../services/api'

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: '100%',
  },
}))

const RequestTicketModal = ({ modalOpen, onClose, ticket, ticketWhatsappId }) => {
  const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
  const actualYear = new Date().getFullYear()
  const protocol = actualMonth + actualYear + ticket.id.toString().padStart(6, '0')

  const [systems, setSystems] = useState([])
  const [employees, setEmployees] = useState([])
  const [companies, setCompanies] = useState([])
  const [localCompanies, setLocalCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(0)
  const [selectedType, setSelectedType] = useState(0)
  const [contact, setContact] = useState('')
  const [date, setDate] = useState(new Date().toLocaleDateString('pt-BR'))
  const [description, setDescription] = useState('')
  const [selectedSystem, setSelectedSystem] = useState(0)

  const [panelAPIToken, setPanelAPIToken] = useState('')

  const classes = useStyles()

  useEffect(() => {
    getPanelAPIToken().then((response) => {
      getSystems(response)
      getEmployees(response)
      getCompanies(response)
      getLocalCompanies(response)
      setPanelAPIToken(response)
    })
  }, [])

  const getPanelAPIToken = async () => {
    const response = await axios.post('https://central.brst.com.br:8080/auth', {
      user: 'SINCRO',
      password: 'SI1122',
    })

    const { token } = response.data
    setPanelAPIToken(token)
    return token
  }

  const getSystems = async (token) => {
    try {
      const { data } = await axios.get('https://central.brst.com.br:8080/api/sistemas', {
        headers: { Authorization: `Bearer ${token || panelAPIToken}` },
      })
      setSystems(data)
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const getEmployees = async (token) => {
    try {
      const { data } = await axios.get('https://central.brst.com.br:8080/api/colaboradores', {
        headers: { Authorization: `Bearer ${token || panelAPIToken}` },
      })
      setEmployees(data)
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const getLocalCompanies = async (token) => {
    try {
      const { data } = await api.get('/companies', {
        headers: { Authorization: `Bearer ${token || panelAPIToken}` },
      })
      setLocalCompanies(data.companies)
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const getCompanies = async (token) => {
    try {
      const { data } = await axios.get('https://central.brst.com.br:8080/api/clientes', {
        headers: { Authorization: `Bearer ${token || panelAPIToken}` },
      })
      setCompanies(data)
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const handleClose = () => {
    onClose()
    setSelectedUser(0)
    setSelectedSystem(0)
    setSelectedType(0)
  }

  const create = async (e) => {
    e.preventDefault()
    if (!ticket.id) return
    setLoading(true)
    try {
      const localCompany = localCompanies.find((company) => company.id === ticket.contact.companyID)
      const company = companies.find((company) => company.id === localCompany.codEmpresa)

      let body = {
        id_cliente: company.id,
        id_colaborador: selectedUser,
        id_sistema: selectedSystem,
        data: date,
        contato: contact,
        protocolo: protocol,
        descricao: description.trim(),
        tipo: selectedType,
        status: 0,
        atualizacao: 0,
      }

      try {
        await axios.post(`https://central.brst.com.br:8080/api/semanas`, body, {
          headers: {
            Authorization: `Bearer ${panelAPIToken}`,
          },
        })

        setLoading(false)
        handleClose()
      } catch (err) {
        toastError(err)
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="xs" scroll="paper">
      <form onSubmit={create}>
        <DialogTitle id="form-dialog-title">Cadastrar Solicitação ({protocol})</DialogTitle>
        <DialogTitle
          style={{ display: 'flex', flexWrap: 'wrap', lineBreak: 'auto' }}
          id="form-dialog-title"
        >
          {ticket.contact?.name}{' '}
          {`(${
            companies.find(
              (company) =>
                company.id ===
                localCompanies.find((company) => company.id === ticket.contact.companyID)
                  ?.codEmpresa
            )?.nome || 'sem vínculo'
          })`}
        </DialogTitle>
        {ticket.contact.companyID &&
        companies.find(
          (company) =>
            company.id ===
            localCompanies.find((company) => company.id === ticket.contact.companyID)?.codEmpresa
        ) ? (
          <>
            <DialogContent dividers style={{ maxWidth: 450 }}>
              <FormControl variant="outlined" className={classes.maxWidth}>
                <InputLabel>Colaborador</InputLabel>
                <Select
                  style={{ marginBottom: 20 }}
                  required
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                  label={i18n.t('transferTicketModal.fieldQueuePlaceholder')}
                >
                  <MenuItem value={0}>Nenhum</MenuItem>
                  {employees.length
                    ? employees.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                          {employee.nome}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.maxWidth}>
                <InputLabel>Sistema</InputLabel>
                <Select
                  style={{ marginBottom: 20 }}
                  required
                  value={selectedSystem}
                  onChange={(e) => setSelectedSystem(e.target.value)}
                  label={i18n.t('transferTicketModal.fieldQueuePlaceholder')}
                >
                  <MenuItem selected value={0}>
                    Nenhum
                  </MenuItem>
                  {systems.length
                    ? systems.map((system) => (
                        <MenuItem key={system.id} value={system.id}>
                          {system.descricao}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.maxWidth}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  style={{ marginBottom: 20 }}
                  required
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  label="Tipo"
                >
                  <MenuItem key={0} value={0}>
                    Nenhum
                  </MenuItem>
                  <MenuItem key="1" value={1}>
                    Inovação
                  </MenuItem>
                  <MenuItem key="2" value={2}>
                    Treinamento
                  </MenuItem>
                  <MenuItem key="3" value={3}>
                    Problemas/Erros
                  </MenuItem>
                  <MenuItem key="4" value={4}>
                    Ajustes
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.maxWidth}>
                <TextField
                  style={{ marginBottom: 20 }}
                  label="Contato"
                  variant="outlined"
                  required
                  onChange={(e) => setContact(e.target.value)}
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.maxWidth}>
                <TextField
                  style={{ marginBottom: 20 }}
                  label="Descrição"
                  variant="outlined"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.maxWidth}>
                <TextField
                  label="Data"
                  variant="outlined"
                  placeholder="dd/mm/aaaa"
                  value={date}
                  error={
                    date
                      ? !(
                          date.includes('/') &&
                          date.split('/')[0] > 0 &&
                          date.split('/')[0] <= 31 &&
                          date.split('/')[1] > 0 &&
                          date.split('/')[1] < 13 &&
                          date.split('/')[2]?.length === 4 &&
                          date.split('/')[2] > 2018 &&
                          date.split('/')[2] < new Date().getFullYear() + 1
                        )
                      : false
                  }
                  required
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
                {i18n.t('transferTicketModal.buttons.cancel')}
              </Button>
              <ButtonWithSpinner
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                {i18n.t('transferTicketModal.buttons.ok')}
              </ButtonWithSpinner>
            </DialogActions>
          </>
        ) : (
          <DialogContent dividers style={{ maxWidth: 450 }}>
            <h3>
              Contato não vinculado, vá até a página de <a href="/contacts">contatos</a> e vincule-o
              a respectiva empresa.
            </h3>
          </DialogContent>
        )}
      </form>
    </Dialog>
  )
}

export default RequestTicketModal
