import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  observationBox: {
    backgroundColor: '#EEEEEE',
    padding: '20px 16px 16px 16px',
    position: 'relative',
  },
  title: {
    fontWeight: 'bold',
    position: 'absolute',
    top: 15,
    left: 15,
    color: '#aeaeae',
  },
}))

export const TicketObservation = ({ observation }) => {
  const classes = useStyles()

  return (
    <div className={classes.observationBox}>
      <span className={classes.title}>OBSERVAÇÃO</span>
      <br />
      <span>{observation}</span>
    </div>
  )
}
