import React, { useState, useEffect, useRef } from 'react'

import * as Yup from 'yup'
import { Formik, FieldArray, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CircularProgress from '@material-ui/core/CircularProgress'

import { i18n } from '../../translate/i18n'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import { MenuItem } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  number: Yup.string().min(8, 'Too Short!').max(50, 'Too Long!'),
  email: Yup.string().email('Invalid email'),
})

const LinkCompanyToContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles()
  const isMounted = useRef(true)

  const initialState = {
    name: '',
    number: '',
    email: '',
  }

  const [contact, setContact] = useState(initialState)
  const [companies, setCompanies] = useState([])

  const [selectedCompanyId, setSelectedCompanyId] = useState()

  useEffect(() => {
    getCompanies()

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues }
        })
      }

      if (!contactId) return

      try {
        const { data } = await api.get(`/contacts/${contactId}`)
        if (isMounted.current) {
          setContact(data)
        }
      } catch (err) {
        toastError(err)
      }
    }

    fetchContact()
  }, [contactId, open, initialValues])

  const getCompanies = async () => {
    const { data } = await api.get('/companies')

    setCompanies(data.companies)
  }

  const handleClose = () => {
    onClose()
    setContact(initialState)
  }

  const handleLinkCompany = async () => {
    try {
      await api.put(`/contacts/${contactId}/company/${selectedCompanyId}`)
      handleClose()
      toast.success(i18n.t('contactModal.success'))
    } catch (err) {
      toastError(err)
    }
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">Vincular empresa ao contato</DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleLinkCompany()
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  Empresa
                </Typography>
                <div>
                  <Field
                    as={Select}
                    label="Empresa"
                    name="companyID"
                    error={touched.companyId && Boolean(errors.companyId)}
                    helperText={touched.codmpanyId && errors.companyId}
                    placeholder="Nome da empresa"
                    fullWidth
                    margin="dense"
                    scroll
                    variant="outlined"
                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                  >
                    <MenuItem value={null} selected>
                      Nenhuma
                    </MenuItem>
                    {companies.length &&
                      companies.map((company) => (
                        <MenuItem value={company.id}>{company.name}</MenuItem>
                      ))}
                  </Field>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('contactModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  VINCULAR
                  {isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default LinkCompanyToContactModal
