import React, { useState, useEffect, useReducer, useContext } from 'react'
import openSocket from '../../services/socket-io'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import BuildIcon from '@material-ui/icons/Build'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import ContactModal from '../../components/ContactModal'
import ConfirmationModal from '../../components/ConfirmationModal/'

import { i18n } from '../../translate/i18n'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import MainContainer from '../../components/MainContainer'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { Can } from '../../components/Can'
import LinkCompanyToContactModal from '../../components/LinkCompanyToContactModal'

const reducer = (state, action) => {
  if (action.type === 'LOAD_CONTACTS') {
    const contacts = action.payload
    const newContacts = []

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id)
      if (contactIndex !== -1) {
        state[contactIndex] = contact
      } else {
        newContacts.push(contact)
      }
    })

    return [...state, ...newContacts]
  }

  if (action.type === 'UPDATE_CONTACTS') {
    const contact = action.payload
    const contactIndex = state.findIndex((c) => c.id === contact.id)

    if (contactIndex !== -1) {
      state[contactIndex] = contact
      return [...state]
    } else {
      return [contact, ...state]
    }
  }

  if (action.type === 'DELETE_CONTACT') {
    const contactId = action.payload

    const contactIndex = state.findIndex((c) => c.id === contactId)
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1)
    }
    return [...state]
  }

  if (action.type === 'RESET') {
    return []
  }
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
}))

const Contacts = () => {
  const classes = useStyles()
  const history = useHistory()

  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParam, setSearchParam] = useState('')
  const [contacts, dispatch] = useReducer(reducer, [])
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [linkCompanyToContactModalOpen, setLinkCompanyToContactModalOpen] = useState(false)
  const [deletingContact, setDeletingContact] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    dispatch({ type: 'RESET' })
    setPageNumber(1)
  }, [searchParam])

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get('/contacts/', {
            params: { searchParam, pageNumber },
          })
          dispatch({ type: 'LOAD_CONTACTS', payload: data.contacts[0] })
          setHasMore(data.hasMore)
          setLoading(false)
        } catch (err) {
          toastError(err)
        }
      }
      fetchContacts()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchParam, pageNumber])

  useEffect(() => {
    const socket = openSocket()

    socket.on('contact', (data) => {
      if (data.action === 'update' || data.action === 'create') {
        dispatch({ type: 'UPDATE_CONTACTS', payload: data.contact })
      }

      if (data.action === 'delete') {
        dispatch({ type: 'DELETE_CONTACT', payload: +data.contactId })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase())
  }

  const handleOpenContactModal = () => {
    setSelectedContactId(null)
    setContactModalOpen(true)
  }

  const handleCloseContactModal = () => {
    setSelectedContactId(null)
    setContactModalOpen(false)
  }

  const handleOpenLinkCompanyModal = (contactId) => {
    setSelectedContactId(contactId)
    setLinkCompanyToContactModalOpen(true)
  }

  const handleCloseLinkCompanyModal = () => {
    setSelectedContactId(null)
    setLinkCompanyToContactModalOpen(false)
  }

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return
    setLoading(true)
    try {
      const { data: ticket } = await api.post('/tickets', {
        contactId: contactId,
        userId: user?.id,
        status: 'open',
      })
      history.push(`/tickets/${ticket.id}`)
    } catch (err) {
      toastError(err)
    }
    setLoading(false)
  }

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId)
    setContactModalOpen(true)
  }

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`)
      toast.success(i18n.t('contacts.toasts.deleted'))
    } catch (err) {
      toastError(err)
    }
    setDeletingContact(null)
    setSearchParam('')
    setPageNumber(1)
  }

  const handleimportContact = async () => {
    try {
      await api.post('/contacts/import')
      history.go(0)
    } catch (err) {
      toastError(err)
    }
  }

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1)
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore()
    }
  }

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>
      <LinkCompanyToContactModal
        open={linkCompanyToContactModalOpen}
        onClose={handleCloseLinkCompanyModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></LinkCompanyToContactModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t('contacts.confirmationModal.deleteTitle')} ${deletingContact.name}?`
            : `${i18n.t('contacts.confirmationModal.importTitlte')}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact ? handleDeleteContact(deletingContact.id) : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t('contacts.confirmationModal.deleteMessage')}`
          : `${i18n.t('contacts.confirmationModal.importMessage')}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t('contacts.title')}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t('contacts.searchPlaceholder')}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: 'gray' }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={(e) => setConfirmOpen(true)}>
            {i18n.t('contacts.buttons.import')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpenContactModal}>
            {i18n.t('contacts.buttons.add')}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t('contacts.table.name')}</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.whatsapp')}</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.email')}</TableCell>
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">{contact.company}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleSaveTicket(contact.id)}>
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => hadleEditContact(contact.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleOpenLinkCompanyModal(contact.id)}>
                      <BuildIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true)
                            setDeletingContact(contact)
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  )
}

export default Contacts
