import React, { useContext } from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import useTickets from '../../hooks/useTickets'

import { AuthContext } from '../../context/Auth/AuthContext'

import { i18n } from '../../translate/i18n'

import Chart from './Chart'
import { useState } from 'react'
import api from '../../services/api'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 240,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 120,
  },
  customFixedHeightPaperSummary: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
}))

const Dashboard = () => {
  const [ticketsOpenByUser, setTicketsOpenByUser] = useState([])
  const [usersSummary, setUsersSummary] = useState([])

  const classes = useStyles()

  const { user } = useContext(AuthContext)
  var userQueueIds = []

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map((q) => q.id)
  }

  const GetTickets = (status, showAll, withUnreadMessages) => {
    const { count } = useTickets({
      status: status,
      showAll: showAll,
      withUnreadMessages: withUnreadMessages,
      queueIds: JSON.stringify(userQueueIds),
    })
    return count
  }

  const GetTicketsOpenByUser = async () => {
    const { data } = await api.get('/tickets/summary/open')
    setTicketsOpenByUser(data.tickets)
  }

  const GetSummariesByUser = async () => {
    const { data } = await api.get('/users/summary')
    setUsersSummary(data)
  }

  useEffect(() => {
    GetTicketsOpenByUser()
    GetSummariesByUser()
  }, [])

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.customFixedHeightPaper} style={{ overflow: 'hidden' }}>
              <Typography component="h3" variant="h6" color="primary" paragraph>
                {i18n.t('dashboard.messages.inAttendance.title')}
              </Typography>
              <Grid item>
                <Typography component="h1" variant="h4">
                  {GetTickets('open', 'true', 'false')}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.customFixedHeightPaper} style={{ overflow: 'hidden' }}>
              <Typography component="h3" variant="h6" color="primary" paragraph>
                {i18n.t('dashboard.messages.waiting.title')}
              </Typography>
              <Grid item>
                <Typography component="h1" variant="h4">
                  {GetTickets('pending', 'true', 'false')}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.customFixedHeightPaper} style={{ overflow: 'hidden' }}>
              <Typography component="h3" variant="h6" color="primary" paragraph>
                {i18n.t('dashboard.messages.closed.title')}
              </Typography>
              <Grid item>
                <Typography component="h1" variant="h4">
                  {GetTickets('closed', 'true', 'false')}
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid container spacing={3} style={{ padding: '15px 0' }}>
            {ticketsOpenByUser.length
              ? ticketsOpenByUser.map((user) => (
                <Grid item xs={3}>
                  <Paper className={classes.customFixedHeightPaperSummary} style={{ overflow: 'hidden' }}>
                    <Typography component="h3" variant="h10" color="primary" paragraph>
                      {user.name}
                    </Typography>
                    <Grid item>
                      <Typography component="h1" variant="h5">
                        {user.count}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
              ))
              : null}
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper}>
              <Chart />
            </Paper>
          </Grid>

          {
            user.profile === "admin" ?
              usersSummary?.map(user => (
                user.profile === "user" && user.isActive ? <Grid item xs={3}>
                  <Paper className={classes.customFixedHeightPaper} style={{ overflow: 'hidden' }}>
                    <Typography component="h5" color="primary" paragraph>
                      {user.name}
                    </Typography>
                    <Grid item>
                      <Typography component="h6">
                        {user.ticketCount} atendimentos
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="h6">
                        {Number(user.averageRating).toFixed(1)} (★ média)
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid> : null

              ))
              : null
          }
        </Grid>
      </Container>
    </div>
  )
}

export default Dashboard
