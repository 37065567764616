import React, { useState, useEffect, useContext } from 'react'

import { AuthContext } from '../../context/Auth/AuthContext'
import { makeStyles } from '@material-ui/core'
import { i18n } from '../../translate/i18n'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ButtonWithSpinner from '../ButtonWithSpinner'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: '100%',
  },
}))

const ResolveTicketModal = ({ modalOpen, onClose, ticket, ticketWhatsappId }) => {
  const { user } = useContext(AuthContext)

  const history = useHistory()
  const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
  const actualYear = new Date().getFullYear()
  const protocol = actualMonth + actualYear + ticket?.id.toString().padStart(6, '0')

  const [loading, setLoading] = useState(false)

  const [ticketStatus, setTicketStatus] = useState(1)
  const [observation, setObservation] = useState(0)

  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const closeTicket = async (e, status, userId) => {
    setLoading(true)

    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        observation,
        finished: ticketStatus,
      })

      setLoading(false)
      handleClose()
      history.push('/tickets')
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="xs" scroll="paper">
      <form>
        <DialogTitle id="form-dialog-title">Finalizar atendimento ({protocol})</DialogTitle>
        <DialogTitle style={{ display: 'flex', flexWrap: 'wrap', lineBreak: 'auto' }} id="form-dialog-title">
          {ticket.contact?.name}
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: 450 }}>
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>Status</InputLabel>
            <Select
              style={{ marginBottom: 20 }}
              required
              value={ticketStatus}
              onChange={(e) => setTicketStatus(e.target.value)}
              label="Tipo"
            >
              <MenuItem key="0" value={1}>
                Finalizado
              </MenuItem>
              <MenuItem key="1" value={0}>
                Pendente
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.maxWidth}>
            <TextField
              style={{ marginBottom: 20 }}
              defaultValue={ticket.observation}
              minRows={5}
              label="Observação"
              variant="outlined"
              required
              multiline
              onChange={(e) => setObservation(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose()} color="secondary" disabled={loading} variant="outlined">
            CANCELAR
          </Button>
          <ButtonWithSpinner
            onClick={(e) => closeTicket(e, 'closed', user?.id)}
            variant="contained"
            type="button"
            color="primary"
            loading={loading}
          >
            FINALIZAR
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ResolveTicketModal
